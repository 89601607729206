import React from 'react'
import Core from '../templates/Core'
import styled from 'styled-components';
import Header from '../page_fragments/Header'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { typeScale } from '../design_system/themeing'
class Content extends React.Component {
  render() {
    const contentFinder = ({ className }) => (
      <div className={className}>
        <CategoryItemWrapper><ActiveCatLink to="/content">All</ActiveCatLink></CategoryItemWrapper>
        <CategoryItemWrapper><CatLinkWrapper to="/projects">Projects</CatLinkWrapper></CategoryItemWrapper>
        <CategoryItemWrapper><CatLinkWrapper to="/writing">Writing</CatLinkWrapper></CategoryItemWrapper>
        <CategoryItemWrapper><CatLinkWrapper to="/video">Video</CatLinkWrapper></CategoryItemWrapper>
      </div>
    );

    const postLink = ({ className, children, to }) => (
      <Link className={className} to={to}>
        {children}
      </Link>
    );

    const CategoryItemWrapper = styled.div`
    margin: auto;
    text-align: center;
    flex-grow: 1;
    `
    const PostDescr = styled.p`
    margin-bottom:0;
    `
    const PostLinkWrapper = styled(postLink)`
      color: ${props => props.theme.textColor};
      text-decoration: none;
      
      `
    const postTag = ({ className, children }) => (
      <div className={className}>
        {children}
      </div>
    );
    const PostTagWrapper = styled(postTag)`
        display:flex;
        flow-direction:row;
        justify-content: space-between;
        color: ${props => props.theme.textColorOnPrimary};
        font-size: ${typeScale.helperText};
        padding: 0;
        margin: 0;
      `

    const contentCard = ({ className, title, content, tag, date, to }) => (
      <div className={className}>
        <PostLinkWrapper to={to}>
          <h2>{title}</h2>
          <PostDescr>{content}</PostDescr>
          <PostTagWrapper>
            <p>{tag}</p>
            <p>{date}</p>
          </PostTagWrapper>
        </PostLinkWrapper>
      </div>
    );
    const CatLinkWrapper = styled(postLink)`
      color: ${props => props.theme.textColor};
      margin:0;
      padding:.25em .5em;
      transition: background-color 0.2s linear, color 0.2s linear;
      border-radius: 5px;
      &:hover {
        background-color: ${props => props.theme.primaryHoverColor};
        color: ${props => props.theme.white};
      }
    `
    const ActiveCatLink = styled(CatLinkWrapper)`
    background-color: ${props => props.theme.primaryHoverColor};
        color: ${props => props.theme.white};
    `
    const ContentFinderWrapper = styled(contentFinder)`
      display:flex;
      border-bottom: solid 1px;
      padding-bottom: .5em;
      `
    const ContentCardWrapper = styled(contentCard)`
      border: 1px solid ${props => props.theme.textColor};
      border-radius: 5px;
      margin: 1em;
      padding:0 5px;
      &:hover{
        box-shadow: 4px 4px ${props => props.theme.dropShadowColor};
        transition: box-shadow 0.3s ease-in-out;
      }
      `

    const PageWrapper = styled.div`
      display: flex;
      flex-direction: column;
      color: ${props => props.theme.textColor};
      text-align: center;
      width:100%
      `

    const posts = get(this, 'props.data.allCosmicjsPosts.edges')
    const location = get(this, 'props.location')

    return (
      <Core >
        <Helmet>
          <title>Justin Isbell-Projects&Posts</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Here are Justin Isbells Posts, Projects, and any other content he has produced."></meta>
          <link rel="canonical" href="https://www.justinisbell.org/content" />
        </Helmet>
        <Header />
        <PageWrapper>
          <h1>Projects and Posts</h1>
          <p>Here you will find a few projects and things I have written. I do not write consistently and this isn't meant to be
          a blog but rather a place to share my work that had other reasons/drivers for existing.
          </p>
          <main>
            <ContentFinderWrapper />
            <div className="cards">
              {posts.map(({ node }) => {
                const title = get(node, 'title') || node.slug
                return (
                  <ContentCardWrapper title={title} content={node.metadata.description} tag={node.metadata.tag} date={node.created} to={"/posts/" + node.slug} />
                )
              })}
            </div>
          </main>
        </PageWrapper>
      </Core >
    )
  }
}

export default Content

export const articleQuery = graphql`
  query ArticleQuery {
    allCosmicjsPosts(sort: { fields: [created], order: DESC }, limit: 1000) {
      edges {
        node {
          metadata {
            description
            tag
          }
          slug
          title
          created(formatString: "DD MMMM, YYYY")
        }
      }
    }
    cosmicjsSettings(slug: { eq: "general" }) {
      metadata {
        site_title
        author_name
        author_bio
        author_avatar {
          imgix_url
        }
      }
    }
  }
`